import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '|shared';

import {DocumentTasksRoutingModule} from './document-tasks-routing.module';
import {DocumentTasksComponent} from './document-tasks.component';

@NgModule({
  declarations: [DocumentTasksComponent],
  imports: [CommonModule, SharedModule, DocumentTasksRoutingModule],
})
export class DocumentTasksModule {}
