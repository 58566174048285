import {Component, inject, OnInit} from '@angular/core';
import {TabItem} from '@icz/angular-essentials';
import {BehaviorSubject} from 'rxjs';
import {ApiCirculationTaskSystemService} from '|api/flow';
import {
  AbstractUnitViewWithTabsComponent,
  CirculationSearchService,
  CirculationView,
  createTabTableId,
  CurrentSessionService,
  EsslComponentSearchService,
  MyActivitiesDatasource,
  MyTasksDatasource,
  TableWithTabsViewMetadata,
  TaskViewType
} from '|shared';

/**
 * Task views can contain both tasks and activities.
 * - views of tasks are prefixed with "TASKS_",
 * - views of activities are prefixed with "ACTIVITIES_".
 */
export enum TaskView {
  TASKS_MY_IN_PROGRESS = 'myTasks',
  ACTIVITIES_BY_ME = 'byMe',
  TASKS_RESOLVED = 'resolved',
  UNIT_TASKS = 'unitTasks',
  CREATED_BY_UNIT = 'createdByUnit',
  RESOLVED_BY_UNIT = 'resolvedByUnit'
}

const NONUNIT_TASK_VIEW_TABS: TabItem<TaskView>[] = [
  {
    id: TaskView.TASKS_MY_IN_PROGRESS,
    label: 'Moje úkoly',
  },
  {
    id: TaskView.ACTIVITIES_BY_ME,
    label: 'Mnou zadané úkoly',
  },
  {
    id: TaskView.TASKS_RESOLVED,
    label: 'Vyřešené úkoly',
  },
];

const UNIT_TASK_VIEW_TABS: TabItem<TaskView>[] = [
  {
    id: TaskView.UNIT_TASKS,
    label: 'Úkoly útvaru',
  },
  {
    id: TaskView.CREATED_BY_UNIT,
    label: 'Zadané útvarem',
  },
  {
    id: TaskView.RESOLVED_BY_UNIT,
    label: 'Vyřešené útvarem',
  },
];

interface CirculationViewMetadata extends TableWithTabsViewMetadata<TaskView> {
  circulationView: CirculationView;
}


@Component({
  selector: 'icz-document-tasks',
  templateUrl: './document-tasks.component.html',
  styleUrls: ['./document-tasks.component.scss'],
})
export class DocumentTasksComponent extends AbstractUnitViewWithTabsComponent<TaskView, CirculationViewMetadata> implements OnInit {

  private currentSessionService = inject(CurrentSessionService);
  private searchService = inject(CirculationSearchService);
  private taskSystemService = inject(ApiCirculationTaskSystemService);
  private esslComponentSearchService = inject(EsslComponentSearchService);

  tabs$!: BehaviorSubject<TabItem<TaskView>[]>;

  protected viewsEnum = TaskView;
  protected defaultView = TaskView.TASKS_MY_IN_PROGRESS;

  get circulationTableView(): Nullable<CirculationView> {
    return this.activeViewMetadata?.circulationView;
  }

  readonly TaskView = TaskView;

  override ngOnInit() {
    if (this.isUnitView) {
      this.tabs$ = new BehaviorSubject(UNIT_TASK_VIEW_TABS);
      this.defaultView = TaskView.UNIT_TASKS;
    }
    else {
      this.tabs$ = new BehaviorSubject(NONUNIT_TASK_VIEW_TABS);
      this.defaultView = TaskView.TASKS_MY_IN_PROGRESS;
    }

    super.ngOnInit();
  }

  protected getViewMetadata(tabView: TaskView, selectorValue: Nullable<number>): CirculationViewMetadata {
    switch (tabView) {
      case TaskView.TASKS_MY_IN_PROGRESS:
        return {
          tabView,
          circulationView: CirculationView.TASKS_IN_PROGRESS,
          dataSource: new MyTasksDatasource(
            this.searchService,
            this.taskSystemService,
            this.currentSessionService,
            this.esslComponentSearchService,
            TaskViewType.IN_PROGRESS,
            {isUnitView: this.isUnitView, orgUnitId: selectorValue}
          ),
          tableId: createTabTableId(TaskView.TASKS_MY_IN_PROGRESS + 'document-tasks'),
        };
      case TaskView.ACTIVITIES_BY_ME:
        return {
          tabView,
          circulationView: CirculationView.ACTIVITIES_IN_PROGRESS,
          dataSource: new MyActivitiesDatasource(
            this.searchService,
            this.currentSessionService,
            this.esslComponentSearchService,
            {isUnitView: this.isUnitView, orgUnitId: selectorValue}
          ),
          tableId: createTabTableId(TaskView.ACTIVITIES_BY_ME + 'document-tasks'),
        };
      case TaskView.TASKS_RESOLVED:
        return {
          tabView,
          circulationView: CirculationView.TASKS_DONE,
          dataSource: new MyTasksDatasource(
            this.searchService,
            this.taskSystemService,
            this.currentSessionService,
            this.esslComponentSearchService,
            TaskViewType.RESOLVED,
            {isUnitView: this.isUnitView, orgUnitId: selectorValue}
          ),
          tableId: createTabTableId(TaskView.TASKS_RESOLVED + 'document-tasks'),
        };
      case TaskView.UNIT_TASKS:
        return {
          tabView,
          circulationView: CirculationView.UNIT_TASKS_IN_PROGRESS,
          dataSource: new MyTasksDatasource(
            this.searchService,
            this.taskSystemService,
            this.currentSessionService,
            this.esslComponentSearchService,
            TaskViewType.IN_PROGRESS,
            {isUnitView: this.isUnitView, orgUnitId: selectorValue}
          ),
          tableId: createTabTableId(TaskView.UNIT_TASKS + 'document-tasks'),
        };
      case TaskView.CREATED_BY_UNIT:
        return {
          tabView,
          circulationView: CirculationView.UNIT_ACTIVITIES_IN_PROGRESS,
          dataSource: new MyActivitiesDatasource(
            this.searchService,
            this.currentSessionService,
            this.esslComponentSearchService,
            {isUnitView: this.isUnitView, orgUnitId: selectorValue}
          ),
          tableId: createTabTableId(TaskView.CREATED_BY_UNIT + 'document-tasks'),
        };
      case TaskView.RESOLVED_BY_UNIT:
        return {
          tabView,
          circulationView: CirculationView.UNIT_TASKS_DONE,
            dataSource: new MyTasksDatasource(
            this.searchService,
            this.taskSystemService,
            this.currentSessionService,
            this.esslComponentSearchService,
            TaskViewType.RESOLVED,
            {isUnitView: this.isUnitView, orgUnitId: selectorValue}
          ),
          tableId: createTabTableId(TaskView.RESOLVED_BY_UNIT + 'document-tasks'),
        };
    }
  }

}
