import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IczRoutes} from '|shared';
import {DocumentTasksComponent} from './document-tasks.component';

const routes: IczRoutes = [{path: '', component: DocumentTasksComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentTasksRoutingModule {}
